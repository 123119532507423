import React, { useEffect, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { BsArrowRightSquare } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { Atom_To_Smarts } from '../constant';
import {
    setLoading, setMainFeedMol, setMainFeedChanged, setMainFeedUnchanged, setMainFeedReference, setMainFeedBondRef,
    setMainFeedBranchesChanged, setMainFeedBranchesUnchanged
} from './reducer';
import { postData } from '../apiHelper';


export default function MainFeed() {
    const marvinInstance = useRef(null);
    const dispatch = useDispatch();
    const step1Params = useSelector((state) => state.reaction.step1Params);

    useEffect(() => {
        window.MarvinJSUtil.getPackage('#mainfeed-sketch').then(
            (marvinNameSpace) => {
                marvinInstance.current = marvinNameSpace.sketcherInstance;

                if (step1Params.main_feed_mol) {
                    let importPromise = marvinInstance.current.importStructure("MDL molfile", step1Params.main_feed_mol);
                    importPromise.then(function () { }, function (error) {
                        alert(error);
                    });
                }
            },
            (error) => {
                console.error(`Cannot retrieve marvin instance from iframe:${error}`);
            }
        );
    }, []);

    const selectReference = () => {
        if (marvinInstance && marvinInstance.current) {
            let exportPromise = marvinInstance.current.exportStructure("mol", null);
            exportPromise.then(function (source) {
                if (source) {
                    dispatch(setLoading(true));
                    dispatch(setMainFeedMol(source));

                    let selection = marvinInstance.current.getSelection();
                    let atoms = selection['atoms'];

                    if (atoms) {
                        let exportPromise = marvinInstance.current.exportStructure("mol", null);
                        exportPromise.then(function (source) {
                            if (source) {
                                var atomsList = atoms.replace(/ /g, '').split(',').map(function (item) {
                                    return parseInt(item);
                                });

                                postData(Atom_To_Smarts, {
                                    molblock: source,
                                    atomIDs: atomsList
                                }).then(
                                    (result) => {
                                        if (result !== null) {
                                            dispatch(setMainFeedReference(result));
                                            dispatch(setMainFeedBondRef(atomsList));
                                            dispatch(setLoading(false));
                                        }
                                    },
                                    (error) => {
                                        alert("Error! Please try again!");
                                        dispatch(setLoading(false));
                                    }
                                );
                            }
                        }, function (error) {
                            alert(error);
                            dispatch(setLoading(false));
                        });
                    } else {
                        alert("Please select reference bond");
                        dispatch(setLoading(false));
                    }
                }
            }, function (error) {
                alert(error);
            });
        }
    };

    const selectChanged = () => {
        if (marvinInstance && marvinInstance.current) {
            let exportPromise = marvinInstance.current.exportStructure("mol", null);
            exportPromise.then(function (source) {
                if (source) {
                    dispatch(setLoading(true));
                    dispatch(setMainFeedMol(source));

                    let selection = marvinInstance.current.getSelection();
                    let atoms = selection['atoms'];

                    if (atoms) {
                        let exportPromise = marvinInstance.current.exportStructure("mol", null);
                        exportPromise.then(function (source) {
                            if (source) {
                                var atomsList = atoms.replace(/ /g, '').split(',').map(function (item) {
                                    return parseInt(item);
                                });

                                postData(Atom_To_Smarts, {
                                    molblock: source,
                                    atomIDs: atomsList
                                }).then(
                                    (result) => {
                                        if (result !== null) {
                                            dispatch(setMainFeedChanged(result));
                                            dispatch(setMainFeedBranchesChanged(atomsList));
                                            dispatch(setLoading(false));
                                        }
                                    },
                                    (error) => {
                                        alert("Error! Please try again!");
                                        dispatch(setLoading(false));
                                    }
                                );
                            }
                        }, function (error) {
                            alert(error);
                            dispatch(setLoading(false));
                        });
                    } else {
                        alert("Please select changed substructure");
                        dispatch(setLoading(false));
                    }
                }
            }, function (error) {
                alert(error);
            });
        }
    };

    const selectUnchanged = () => {
        if (marvinInstance && marvinInstance.current) {
            let exportPromise = marvinInstance.current.exportStructure("mol", null);
            exportPromise.then(function (source) {
                if (source) {
                    dispatch(setLoading(true));
                    dispatch(setMainFeedMol(source));

                    let selection = marvinInstance.current.getSelection();
                    let atoms = selection['atoms'];

                    if (atoms) {
                        let exportPromise = marvinInstance.current.exportStructure("mol", null);
                        exportPromise.then(function (source) {
                            if (source) {
                                var atomsList = atoms.replace(/ /g, '').split(',').map(function (item) {
                                    return parseInt(item);
                                });

                                postData(Atom_To_Smarts, {
                                    molblock: source,
                                    atomIDs: atomsList
                                }).then(
                                    (result) => {
                                        if (result !== null) {
                                            dispatch(setMainFeedUnchanged(result));
                                            dispatch(setMainFeedBranchesUnchanged(atomsList));
                                            dispatch(setLoading(false));
                                        }
                                    },
                                    (error) => {
                                        alert("Error! Please try again!");
                                        dispatch(setLoading(false));
                                    }
                                );
                            }
                        }, function (error) {
                            alert(error);
                            dispatch(setLoading(false));
                        });
                    } else {
                        alert("Please select unchanged substructure");
                        dispatch(setLoading(false));
                    }
                }
            }, function (error) {
                alert(error);
            });
        }
    };

    return (
        <div className="select-feedstock">
            <div className='title'>
                Draw the main feedstock and select changed & unchanged substructure
            </div>
            <>
                <Row>
                    <Col xs={6}>
                        <iframe id="mainfeed-sketch" title="feedstock-sketch"
                            src={process.env.PUBLIC_URL + "/marvinjs/editor.html"}
                            className="feedstock-marvin" />
                    </Col>
                    <Col xs={2}>
                        <div className='select-btn'>
                            <div>
                                1. Select a reference bond and click the arrow to select
                            </div>
                            <Button onClick={selectReference} variant="primary">
                                <BsArrowRightSquare />
                            </Button>
                        </div>
                        <div className='select-btn'>
                            <div>
                                2. Highlight atoms of unchanged substructure and click the arrow to select
                            </div>
                            <Button onClick={selectUnchanged} variant="primary">
                                <BsArrowRightSquare />
                            </Button>
                        </div>
                        <div className='select-btn'>
                            <div>
                                3. Highlight atoms of changed substructure and click the arrow to select
                            </div>
                            <Button onClick={selectChanged} variant="primary">
                                <BsArrowRightSquare />
                            </Button>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className='reference-structure'>
                            {step1Params.main_feed_img_reference &&
                                <>
                                    <p>Reference Bond:</p>
                                    <img src={"data:image/png;base64," + step1Params.main_feed_img_reference.frag_pil} alt="" />
                                </>
                            }
                        </div>
                        <div className='unchanged-structure'>
                            {step1Params.main_feed_img_unchanged &&
                                <>
                                    <p>Unchanged Structure:</p>
                                    <img src={"data:image/png;base64," + step1Params.main_feed_img_unchanged.frag_pil} alt="" />
                                </>
                            }
                        </div>
                        <div className='changed-structure'>
                            {step1Params.main_feed_img_changed &&
                                <>
                                    <p>Changed Structure:</p>
                                    <img src={"data:image/png;base64," + step1Params.main_feed_img_changed.frag_pil} alt="" />
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </>
        </div>
    );
}