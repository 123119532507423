import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spinner, Row, Col } from 'react-bootstrap';
import {
    setShowMoleModal, setStep, setShowSummaryModal, setLoading, setReportData
} from './reducer';
import MoleModal from './modals/mole-modal';
import SummaryModal from './modals/summary-modal';
import { postData } from '../apiHelper';
import { Reaction_View_Summary } from '../constant';


export default function Result() {
    const dispatch = useDispatch();
    const step1Result = useSelector((state) => state.reaction.step1Result);
    const step2Params = useSelector((state) => state.reaction.step2Params);
    const step2Routes = useSelector((state) => state.reaction.step2Routes);
    const isLoading = useSelector((state) => state.reaction.isLoading);
    const showSummaryModal = useSelector((state) => state.reaction.showSummaryModal);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const viewMolecules = () => {
        setTimeout(() => dispatch(setShowMoleModal(true)), 1000);
    }

    const viewSummary = () => {
        dispatch(setLoading(true));

        postData(Reaction_View_Summary, {
            reaction_selections: {
                "type": "ranked",
                "top-n": 50,
                "compID": "",
                "range": []
            }
        }).then(
            (data) => {
                if (data && data.report_data) {
                    console.log(data.report_data);
                    dispatch(setReportData(data.report_data));
                    dispatch(setStep(2));
                    dispatch(setLoading(false));
                } else {
                    alert("Server error, please try again!");
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );

        setTimeout(() => dispatch(setShowSummaryModal(true)), 1000);
    }

    const goBack = () => {
        dispatch(setStep(1));
    }

    return (
        <div className='search-result'>
            <div>
                <div className='title'>Summary of found synthetic routes:</div>
                <div>Number of reference reactions found: {step2Params.top_n_rxns.max}</div>
            </div>

            {step2Routes && step2Routes.img_encoded &&
                <div style={{ marginTop: 20 + 'px' }}>
                    <Row>
                        <Col xs={12}>
                            <img src={"data:image/png;base64," + step2Routes.img_encoded} alt="" width="100%"/>
                        </Col>
                    </Row>
                </div>
            }

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='title'>View molecules in the network</div>
            <div className='view-molecules'>
                <Button variant="warning" onClick={viewMolecules} disabled={isLoading || step1Result == null}>
                    {isLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: 5 + 'px' }}
                        />
                    }
                    View Molecules
                </Button>
            </div>

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='title'>Preview and download summary</div>
            <div className='view-summary'>
                <Button variant="warning" onClick={viewSummary} disabled={isLoading || step1Result == null}>
                    {isLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: 5 + 'px' }}
                        />
                    }
                    View Summary
                </Button>
            </div>

            <hr style={{ marginTop: 20 + 'px' }} />
            <div className='title'>Back to fine tune or rerun search</div>
            <div className='go-back'>
                <Button variant="warning" onClick={goBack} disabled={isLoading || step1Result == null}>
                    Go Back
                </Button>
            </div>

            {step2Params.showMoleModal ? <MoleModal /> : null}
            {showSummaryModal ? <SummaryModal /> : null}
        </div>
    );
}