import React, { useEffect } from 'react';
import { Container, Button, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './analogue.css';
import Feedstock from './feedstock';
import Target from './target';
import Advanced from './advanced';
import Result from './result';
import {
    setState, resetState, setLoading, setStep, setRxnSteps, setStep1Result, setStep2Routes, setRoutesLength, setSearchStatus, setSearchId,
    setRoutesLengthOptions, setStep2ReactionIds, setStep2ReactionDetails
} from './reducer';
import { postData, postSearchData, getSearchData } from '../apiHelper';
import { Search_API, Analogue_Show_Routes, See_Results_Analogue } from '../constant';


export default function Analogue() {

    const dispatch = useDispatch();
    const steps = [1, 2, 3];
    const currentStep = useSelector((state) => state.analogue.currentStep);
    const isLoading = useSelector((state) => state.analogue.isLoading);
    const step1Params = useSelector((state) => state.analogue.step1Params);
    const step1Result = useSelector((state) => state.analogue.step1Result);
    const searchStatus = useSelector((state) => state.analogue.searchStatus);

    useEffect(() => {
        let useridString = localStorage.getItem("userid");

        if (useridString) {
            let params = localStorage.getItem(useridString + "-analogue-params");
            if (params) {
                let state = JSON.parse(params);
                dispatch(setState(state));

                if (state.isLoading && !state.searchId) {
                    startSearch();
                } else if (state.currentStep === 1 && state.searchId) {
                    updateSearch(state.searchId);
                }

                if (state.currentStep === 2) {
                    seeResults(); // load routes since the result is too big to store in localStorage
                }
            }
        }
    }, []);

    const reset = () => {
        dispatch(resetState());
    }

    const updateSearch = (searchId) => {
        const checkSearchStatus = setInterval(() => {
            getSearchData(Search_API + "/" + searchId).then(
                (searchData) => {
                    console.log(searchData);
                    if (searchData) {
                        switch (searchData.status) {
                            case "PENDING":
                                dispatch(setSearchStatus("Search is pending"));
                                break;
                            case "IN_PROGRESS":
                                if (searchData.progress && searchData.progress.message) {
                                    let message = searchData.progress.message;
                                    dispatch(setSearchStatus(message));
                                    if (message.includes('No') || message.includes('stopped') || message.includes('exited')) {
                                        dispatch(setLoading(false));
                                        clearInterval(checkSearchStatus);
                                    }
                                } else {
                                    dispatch(setSearchStatus("Search is in progress"));
                                }
                                break;
                            case "COMPLETE":
                                if (searchData.progress && searchData.progress.message) {
                                    dispatch(setSearchStatus(searchData.progress.message));
                                } else {
                                    dispatch(setSearchStatus("Search completed, please click below button to see the results"));
                                }
                                dispatch(setLoading(false));
                                dispatch(setStep1Result(searchData.result));
                                clearInterval(checkSearchStatus);
                                break;
                            case "ERROR":
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                            default:
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                    clearInterval(checkSearchStatus);
                }
            );
        }, 5000);
    }

    const startSearch = () => {
        if (!step1Params.feed_mol) {
            alert("Please specify a feedstock!");
            return;
        }
        if (!step1Params.target_mol) {
            alert("Please specify a target!");
            return;
        }

        dispatch(setLoading(true));
        dispatch(setSearchStatus("Not Started"));
        dispatch(setSearchId(""));

        let request = {
            search_type: "analog",
            search_params: {
                initializer: {
                    feed: {
                        molblock: step1Params.feed_mol,
                        bond_ref: step1Params.feed_bond_ref,
                        branches_unchange: step1Params.feed_branches_unchanged,
                        branches_change: step1Params.feed_branches_changed
                    },
                    target: {
                        molblock: step1Params.target_mol,
                        bond_ref: step1Params.target_bond_ref,
                        branches_unchange: step1Params.target_branches_unchanged,
                        branches_change: step1Params.target_branches_changed
                    },
                    similarity: {
                        sim_thresh: parseFloat(step1Params.sim_thresh),
                        fp_type: "Independent"
                    },
                    explicit_H: step1Params.explicit_H === "True" ? true : false,
                    ring: step1Params.atom_in_ring === "True" ? true : false,
                    analogue_comp_size_coeff: 2,
                    addHs: false,
                    sort_atm_idxs: true,
                    molblock_type: "marvin",
                    comps_pool_size: null
                },
                route_search: {
                    rxn_steps: parseInt(step1Params.rxn_steps),
                    check_stereo: step1Params.chirality === "True" ? true : false,
                    walk_steps: 3,
                    max_search_time: 60
                },
                compile_results: {
                    "top_n_routes": 200,
                    "top_n_rxnIDs": 10
                }
            }
        };

        postSearchData(Search_API, request).then(
            (result) => {
                if (result && result.message === "Created") {
                    dispatch(setStep1Result(null));

                    if (result.searchId) {
                        dispatch(setSearchId(result.searchId));
                        updateSearch(result.searchId);
                    }
                } else {
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    const seeResults = () => {
        dispatch(setLoading(true));

        if (currentStep === 1) {
            // Always show results based on default parameters when click: “See Results”
            dispatch(setStep2ReactionIds(""));
            dispatch(setStep2ReactionDetails(null));
        }

        postData(See_Results_Analogue).then(
            (result) => {
                if (result) {
                    dispatch(setRoutesLength(result.len_routes.default));
                    dispatch(setRoutesLengthOptions(result.len_routes.range));
                    postData(Analogue_Show_Routes, {
                        path_n: result.len_routes.default
                    }).then(
                        (routesData) => {
                            if (routesData) {
                                console.log(routesData);
                                dispatch(setStep2Routes(routesData));
                                dispatch(setStep(2));
                                dispatch(setLoading(false));
                            } else {
                                alert("Server error, please try again!");
                                dispatch(setLoading(false));
                            }
                        },
                        (error) => {
                            console.log(error);
                            dispatch(setLoading(false));
                        }
                    );
                } else {
                    alert("Server error, please try again!");
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    return (
        <div className='analogue'>
            <Container>
                <div className='title'>
                    Search Analogue Routes

                    {currentStep === 1 &&
                        <div className='reset'>
                            <Button variant="warning" onClick={reset}>
                                Reset
                            </Button>
                        </div>
                    }
                </div>
                <div className='sub-title'>
                    {currentStep === 1 &&
                        <strong>Stage 1: Set up parameters and initiate search</strong>
                    }
                    {currentStep === 2 &&
                        <strong>Stage 2: View analogue results</strong>
                    }
                </div>
                {currentStep === 1 &&
                    <>
                        <div className='description'>
                            This application help you to find analogue routes that are similar to the chemical transformations you want to achieve. So that new synthetic plans can be inspired by these results. The chemical transformation can be as simple as dehydration or substitution, as well as a complex cyclization reaction. These analogue routes (a single reaction or a multistep route) share similar substructure around the reaction center of the target transformation.
                        </div>
                        <Feedstock />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <Target />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <>
                            <div className='rxn-step-title'>
                                Number of synthetic steps
                            </div>
                            <DropdownButton title={step1Params.rxn_steps} className='n-steps'>
                                {steps.map((n) =>
                                    <Dropdown.Item onClick={() => dispatch(setRxnSteps(n))} key={n}>
                                        {n}
                                    </Dropdown.Item>
                                )}
                            </DropdownButton>
                        </>
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <div>
                            <Button onClick={startSearch} variant="primary" disabled={isLoading}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                Start Route Search
                            </Button>
                        </div>
                        {searchStatus !== "Not Started" &&
                            <div className="description" style={{ marginTop: 20 + 'px' }}>
                                {searchStatus}
                            </div>
                        }
                        <div className='see-results'>
                            <Button variant="warning" onClick={seeResults} disabled={isLoading || step1Result == null}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                See Results
                            </Button>
                        </div>
                        <Advanced />
                    </>
                }
                {currentStep === 2 &&
                    <Result />
                }
            </Container>
        </div>
    );
}