import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import User from './user/user';
import Header from './components/header';
import Footer from './components/footer';
import Synthetic from './synthetic/index';
import Analogue from './analogue/index';
import Chiral from './chiral/index';
import Reaction from './reaction/index';
import Network from './network/index';
import store from './store/store';
import reportWebVitals from './reportWebVitals';
// Set up AWS Amplify
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    domain: process.env.REACT_APP_AUTH_DOMAIN_NAME,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

const Main = () => {
  const user = useSelector((state) => state.user.currentUser);

  return (
    <div className='cdi-web'>
      <User />
      {user != null &&
        <Routes>
          <Route path="/" element={<Synthetic />} />
          <Route path="/synthetic" element={<Synthetic />} />
          <Route path="/analogue" element={<Analogue />} />
          <Route path="/chiral" element={<Chiral />} />
          <Route path="/reaction" element={<Reaction />} />
          <Route path="/network" element={<Network />} />
        </Routes>
      }
    </div>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router >
        <Header />
        <Main />
        <Footer />
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
