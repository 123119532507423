import React, { useEffect } from 'react';
import { Container, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './synthetic.css';
import Target from './target';
import Feedstock from './feedstock';
import Params from './params';
import Long from './long';
import Fragment from './fragment';
import FragModal from './modals/frag-modal';
import SolventsModal from './modals/solvents-modal';
import ReagentsModal from './modals/reagents-modal';
import ReactantsModal from './modals/reactants-modal';
import ProductsModal from './modals/products-modal';
import SearchTargetModal from './modals/search-target-modal';
import SearchFeedstockModal from './modals/search-feedstock-modal';
import Advanced from './advanced';
import Result from './result';
import {
    setState, resetState, setLoading, setStep, setStep1Result, setStep2Routes, setSearchStatus, setSearchId, setRankRoutes,
    setRoutesLength, setRoutesLengthOptions, setFeedIdOptions, setSelectedFeedImg, setFeedId, setStep2ReactionIds, setStep2ReactionDetails
} from './reducer';
import { postData, postSearchData, getSearchData } from '../apiHelper';
import { Search_API, Show_Routes, See_Results_Synthetic } from '../constant';


export default function Synthetic() {

    const dispatch = useDispatch();
    const currentStep = useSelector((state) => state.synthetic.currentStep);
    const step1Params = useSelector((state) => state.synthetic.step1Params);
    const step1Result = useSelector((state) => state.synthetic.step1Result);
    const isLoading = useSelector((state) => state.synthetic.isLoading);
    const searchStatus = useSelector((state) => state.synthetic.searchStatus);
    const showSearchTarget = useSelector((state) => state.synthetic.showSearchTarget);
    const showSearchFeedstock = useSelector((state) => state.synthetic.showSearchFeedstock);

    useEffect(() => {
        let useridString = localStorage.getItem("userid");

        if (useridString) {
            let params = localStorage.getItem(useridString + "-synthetic-params");
            if (params) {
                let state = JSON.parse(params);

                // converting
                if (!state.step2Params.feeds_ID) {
                    state.step2Params.feeds_ID = "";
                }
                if (!state.step2Params.feeds_ID_options) {
                    state.step2Params.feeds_ID_options = [];
                }

                dispatch(setState(state));

                if (state.isLoading && !state.searchId) {
                    startSearch();
                } else if (state.currentStep === 1 && state.searchId) {
                    updateSearch(state.searchId);
                }
                
                if (state.currentStep === 2) {
                    seeResults(); // load routes since the result is too big to store in localStorage
                }
            }
        }
    }, []);

    const reset = () => {
        dispatch(resetState());
    }

    const updateSearch = (searchId) => {
        const checkSearchStatus = setInterval(() => {
            getSearchData(Search_API + "/" + searchId).then(
                (searchData) => {
                    console.log(searchData);
                    if (searchData) {
                        switch (searchData.status) {
                            case "PENDING":
                                dispatch(setSearchStatus("Search is pending"));
                                break;
                            case "IN_PROGRESS":
                                if (searchData.progress && searchData.progress.message) {
                                    let message = searchData.progress.message;
                                    dispatch(setSearchStatus(message));
                                    if (message.includes('No') || message.includes('stopped') || message.includes('exited')) {
                                        dispatch(setLoading(false));
                                        clearInterval(checkSearchStatus);
                                    }
                                } else {
                                    dispatch(setSearchStatus("Search is in progress"));
                                }
                                break;
                            case "COMPLETE":
                                if (searchData.progress && searchData.progress.message) {
                                    dispatch(setSearchStatus(searchData.progress.message));
                                } else {
                                    dispatch(setSearchStatus("Search completed, please click below button to see the results"));
                                }
                                dispatch(setLoading(false));
                                dispatch(setStep1Result(searchData.result));
                                clearInterval(checkSearchStatus);
                                break;
                            case "ERROR":
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                            default:
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                    clearInterval(checkSearchStatus);
                }
            );
        }, 5000);
    }

    const startSearch = () => {
        if (step1Params.selectedFeedstocks.length === 0 && step1Params.feedstock_type === "User Draw") {
            alert("Please specify a feedstock!");
            return;
        }
        if (step1Params.selectedTargets.length === 0 && step1Params.targetType === "User Draw") {
            alert("Please specify a target!");
            return;
        }

        var starts = [];
        if (step1Params.feedstock_type === "User Draw") {
            starts = step1Params.selectedFeedstocks.map(function (item) {
                return item.compID;
            });
        }

        var ends = [];
        if (step1Params.targetType === "User Draw") {
            ends = step1Params.selectedTargets.map(function (item) {
                return item.compID;
            });
        }

        let request = {
            search_type: parseInt(step1Params.n_step_search) <= 5 ? "synthetic" : "long",
            search_params: {
                assembler: {
                    search_direction: step1Params.search_direction,
                    feedstocks_type: step1Params.feedstock_type === "User Draw" ? null : step1Params.feedstock_type.toLowerCase(),
                    n_steps_search: parseInt(step1Params.n_step_search),
                    targets_type: step1Params.targetType === "User Draw" ? null : step1Params.targetType.toLowerCase(),
                    ends: ends,
                    starts: starts
                },
                trimer: {
                    yield_trim: parseFloat(step1Params.yield_trim),
                    frag: [],
                    similarity: {
                        thresh: parseFloat(step1Params.similarity.thresh),
                        fpType: step1Params.similarity.fpType
                    },
                    solvents_trim: {
                        action: step1Params.solvents_trim.action === "remove" ? "drop" : "keep",
                        type: step1Params.solvents_trim.type,
                        solvents: []
                    },
                    reagents_trim: {
                        action: step1Params.reagents_trim.action === "remove" ? "drop" : "keep",
                        type: step1Params.reagents_trim.type,
                        reagents: []
                    },
                    reactions_reactants_products: []
                },
                network: {
                    comps_rm: [],
                    rxns_rm: []
                },
                route_search: {
                    rank_on: step1Params.rank_on,
                    heu_route_SRI: step1Params.set_SRI === "No" ? {} : {
                        thresh: parseFloat(step1Params.heu_route_SRI.thresh),
                        n_steps_fail: parseInt(step1Params.heu_route_SRI.n_steps_fail)
                    },
                    heu_route_yield: step1Params.set_yield === "No" ? {} : {
                        thresh: parseFloat(step1Params.heu_route_yield.thresh),
                        n_steps_fail: parseInt(step1Params.heu_route_yield.n_steps_fail)
                    }
                },
                assembler_lr: parseInt(step1Params.n_step_search) <= 5 ? {} : {
                    search_method: parseInt(step1Params.search_method),
                    search_speed: step1Params.search_speed
                },
                compile_results: {
                    top_n_compounds: 50,
                    top_n_routes: 200,
                    top_n_rxnIDs: 10
                }
            }
        };

        if (step1Params.keepFragments.length > 0) {
            step1Params.keepFragments.forEach(function (f) {
                request.search_params.trimer.frag.push({
                    fragment: f.fragment,
                    action: f.action,
                    type: f.type
                });
            });
        }
        if (step1Params.dropFragments.length > 0) {
            step1Params.dropFragments.forEach(function (f) {
                request.search_params.trimer.frag.push({
                    fragment: f.fragment,
                    action: f.action,
                    type: f.type
                });
            });
        }

        if (step1Params.solvents_trim.solvents_string.replace(/\s+/g, '') !== "") {
            var solvents = step1Params.solvents_trim.solvents_string.replace(/\s+/g, '').split(',').join(' ');
            request.search_params.trimer.solvents_trim.solvents = solvents;
        }
        if (step1Params.reagents_trim.reagents_string.replace(/\s+/g, '') !== "") {
            var reagents = step1Params.reagents_trim.reagents_string.replace(/\s+/g, '').split(',').join(' ');
            request.search_params.trimer.reagents_trim.reagents = reagents;
        }

        if (step1Params.reactants_string.replace(/\s+/g, '') !== "") {
            var reactants = step1Params.reactants_string.replace(/\s+/g, '').split(',').join(' ');
            request.search_params.trimer.reactions_reactants_products.push({
                IDs: reactants,
                action: step1Params.reactants_action === "remove" ? "drop" : "keep",
                type: "reactants"
            });
        }
        if (step1Params.products_string.replace(/\s+/g, '') !== "") {
            var products = step1Params.products_string.replace(/\s+/g, '').split(',').join(' ');
            request.search_params.trimer.reactions_reactants_products.push({
                IDs: products,
                action: step1Params.products_action === "remove" ? "drop" : "keep",
                type: "products"
            });
        }

        if (step1Params.remove_molecules_string.replace(/\s+/g, '') !== "") {
            let mole_ids = step1Params.remove_molecules_string.replace(/\s+/g, '').split(',');
            if (mole_ids.length > 0) {
                request.search_params.network.comps_rm = mole_ids;
            } else {
                alert("Invalid molecules IDs. Please separate molecules IDs with ','");
            }
        }
        if (step1Params.remove_reactions_string.replace(/\s+/g, '') !== "") {
            let reac_ids = step1Params.remove_reactions_string.replace(/\s+/g, '').split(',');
            if (reac_ids.length > 0) {
                request.search_params.network.rxns_rm = reac_ids;
            } else {
                alert("Invalid reactions IDs. Please separate reactions IDs with ','");
            }
        }

        dispatch(setLoading(true));
        dispatch(setSearchStatus("Not Started"));
        dispatch(setSearchId(""));

        postSearchData(Search_API, request).then(
            (result) => {
                if (result && result.message === "Created") {
                    dispatch(setStep1Result(null));

                    if (result.searchId) {
                        dispatch(setSearchId(result.searchId));
                        updateSearch(result.searchId);
                    }
                } else {
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    const seeResults = () => {
        dispatch(setLoading(true));

        if(currentStep === 1){
            // Always show results based on default parameters when click: “See Results”
            dispatch(setFeedId(""));
            dispatch(setSelectedFeedImg(null));
            dispatch(setStep2ReactionIds(""));
            dispatch(setStep2ReactionDetails(null));
        }

        postData(See_Results_Synthetic).then(
            (result) => {
                if (result) {
                    dispatch(setRankRoutes(result.rank_on));
                    dispatch(setRoutesLength(result.len_routes.default));
                    dispatch(setRoutesLengthOptions(result.len_routes.range));
                    postData(Show_Routes, {
                        path_n: result.len_routes.default,
                        rank_on: result.rank_on
                    }).then(
                        (routesData) => {
                            if (routesData) {
                                dispatch(setStep2Routes(routesData));
                                dispatch(setStep(2));
                                if (routesData && routesData.feed_ids_routes) {
                                    for (var key in routesData.feed_ids_routes) {
                                        if (routesData.feed_ids_routes.hasOwnProperty(key) && key === result.len_routes.default.toString()) {
                                            dispatch(setFeedIdOptions(routesData.feed_ids_routes[key]));
                                        }
                                    }
                                }
                                
                                dispatch(setLoading(false));
                            } else {
                                alert("Server error, please try again!");
                                dispatch(setLoading(false));
                            }
                        },
                        (error) => {
                            console.log(error);
                            dispatch(setLoading(false));
                        }
                    );
                } else {
                    alert("Server error, please try again!");
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    return (
        <div className='synthetic'>
            <Container>
                <div className='title'>
                    Search Synthetic Routes
                    {currentStep === 1 &&
                        <div className='reset'>
                            <Button variant="warning" onClick={reset}>
                                Reset
                            </Button>
                        </div>
                    }
                </div>
                <div className='sub-title'>
                    {currentStep === 1 &&
                        <strong>Stage 1: Set up parameters and initiate search</strong>
                    }
                    {currentStep === 2 &&
                        <strong>Stage 2: View results: synthetic routes, network and molecules in network</strong>
                    }
                </div>
                {currentStep === 1 &&
                    <>
                        <Target />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <Feedstock />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <Params />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <Fragment />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        {parseInt(step1Params.n_step_search) > 5 &&
                            <>
                                <Long />
                                <hr style={{ marginTop: 30 + 'px' }} />
                            </>
                        }
                        <div>
                            <Button onClick={startSearch} variant="primary" disabled={isLoading}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                Start Route Search
                            </Button>
                        </div>
                        {searchStatus !== "Not Started" &&
                            <div className="description" style={{ marginTop: 20 + 'px' }}>
                                {searchStatus}
                            </div>
                        }
                        <div className='see-results'>
                            <Button variant="warning" onClick={seeResults} disabled={isLoading || step1Result == null}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                See Results
                            </Button>
                        </div>
                        <Advanced />
                    </>
                }
                {currentStep === 2 &&
                    <Result />
                }

                {step1Params.showFragModal ? <FragModal /> : null}
                {step1Params.showSolventsModal ? <SolventsModal /> : null}
                {step1Params.showReagentsModal ? <ReagentsModal /> : null}
                {step1Params.showReactantsModal ? <ReactantsModal /> : null}
                {step1Params.showProductsModal ? <ProductsModal /> : null}
                {showSearchTarget ? <SearchTargetModal /> : null}
                {showSearchFeedstock ? <SearchFeedstockModal /> : null}
            </Container>
        </div>
    );
}