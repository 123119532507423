import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import {
    setShowMoleModal, setLoading, setShowMoleResult, setMoleIDs
} from '../reducer';
import { Analogue_View_Mole_By_Id } from '../../constant';
import { postData } from '../../apiHelper';


function Params() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.analogue.isLoading);
    const step2Params = useSelector((state) => state.analogue.step2Params);
    const showMoleResult = useSelector((state) => state.analogue.showMoleResult);

    const onMoleIDsChange = (event) => {
        var value = event.target.value;
        dispatch(setMoleIDs(value));
    }

    const showMolecules = () => {
        if (step2Params.mole_IDs.replace(/\s+/g, '') === "") {
            alert("Please provide molecule IDs in the network.");
        } else {
            dispatch(setLoading(true));
            dispatch(setShowMoleResult(null));

            postData(Analogue_View_Mole_By_Id, {
                compIDs: step2Params.mole_IDs.replace(/\s+/g, '').split(',')
            }).then(
                (result) => {
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        dispatch(setShowMoleResult(result));
                    }
                    dispatch(setLoading(false));
                },
                (error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                }
            );
        }
    }

    return (
        <div className='show-mole'>
            <div>
                <span>Please provide molecule IDs and separate by ',': </span>
                <Form.Control type="text" value={step2Params.mole_IDs} onChange={onMoleIDsChange} className='molecules-string' />
            </div>

            <div style={{ marginTop: 20 + 'px' }}>
                <Button onClick={showMolecules} variant="primary" disabled={isLoading}>
                    {isLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: 5 + 'px' }}
                        />
                    }
                    Show Molecules
                </Button>
            </div>
            {showMoleResult &&
                <div style={{ marginTop: 20 + 'px' }}>
                    <Row>
                        {Object.entries(showMoleResult).map(([key, value]) =>
                            <Col xs={4} key={key} className="show-mole-item">
                                <img src={"data:image/png;base64," + value} alt="" />
                                <div>
                                    {key}
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            }
        </div >
    );
}

function Footer() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.analogue.isLoading);
    const handleClose = () => dispatch(setShowMoleModal(false));

    return (
        <div className='footer'>
            <hr style={{ marginBottom: 30 + 'px' }} />
            <Button variant="secondary" onClick={handleClose} style={{ marginRight: 20 + 'px' }} disabled={isLoading}>
                Close
            </Button>
        </div>
    );
}


export default function MoleModal() {
    return (
        <div>
            <div className="fade modal-backdrop show"></div>
            <div className='cdi-modal'>
                <div className='cdi-modal-dialog frag'>
                    <div className='cdi-modal-content'>
                        <div className='cdi-modal-title'>
                            View Molecules
                        </div>
                        <hr style={{ marginBottom: 30 + 'px' }} />
                        <Params />
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}