import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (state) => {
    let useridString = localStorage.getItem("userid");
    if (useridString) {
        localStorage.setItem(useridString + "-reaction-params", JSON.stringify({
            isLoading: state.isLoading,
            currentStep: state.currentStep,
            searchStatus: state.searchStatus,
            searchId: state.searchId,
            step1Params: state.step1Params,
            step1Result: state.step1Result,
            step2Params: state.step2Params,
            showSummaryModal: state.showSummaryModal,
            showMoleResult: state.showMoleResult
        }));
    }
}

const initialState = {
    isLoading: false,
    currentStep: 1,
    searchStatus: "Not Started",
    searchId: "",
    step1Params: {
        main_feed_mol: null,
        main_feed_img_reference: null,
        main_feed_img_changed: null,
        main_feed_img_unchanged: null,
        main_feed_bond_ref: null,
        main_feed_branches_changed: null,
        main_feed_branches_unchanged: null,
        co_feed_mol: null,
        co_feed_img_reference: null,
        co_feed_img_changed: null,
        co_feed_img_unchanged: null,
        co_feed_bond_ref: null,
        co_feed_branches_changed: null,
        co_feed_branches_unchanged: null,
        target_mol: null,
        target_img_reference: null,
        target_img_changed: null,
        target_img_unchanged: null,
        target_bond_ref: null,
        target_branches_changed: null,
        target_branches_unchanged: null,
        sim_thresh: "0.5",
        explicit_H: "True",
        atom_in_ring: "False",
    },
    step1Result: null,
    step2Params: {
        top_n_rxns: null,
        showMoleModal: false,
        top_n_mole: "10",
        mole_type: "Solvents"
    },
    step2Routes: null,
    showSummaryModal: false,
    showMoleResult: null,
    reportData: null
};

export const reactionSlice = createSlice({
    name: 'reaction',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.isLoading = action.payload.isLoading;
            state.currentStep = action.payload.currentStep;
            state.searchStatus = action.payload.searchStatus;
            state.searchId = action.payload.searchId;
            state.step1Params = action.payload.step1Params;
            state.step1Result = action.payload.step1Result;
            state.step2Params = action.payload.step2Params;
            state.showSummaryModal = action.payload.showSummaryModal;
            state.showMoleResult = action.payload.showMoleResult;
        },
        setReactionNumber: (state, action) => {
            state.step2Params.top_n_rxns = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTopNMole: (state, action) => {
            state.step2Params.top_n_mole = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMoleType: (state, action) => {
            state.step2Params.mole_type = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowSummaryModal: (state, action) => {
            state.showSummaryModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setReportData: (state, action) => {
            state.reportData = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleModal: (state, action) => {
            state.step2Params.showMoleModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowMoleResult: (state, action) => {
            state.showMoleResult = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchStatus: (state, action) => {
            state.searchStatus = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSearchId: (state, action) => {
            state.searchId = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedBranchesChanged: (state, action) => {
            state.step1Params.main_feed_branches_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedBranchesUnchanged: (state, action) => {
            state.step1Params.main_feed_branches_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedBondRef: (state, action) => {
            state.step1Params.main_feed_bond_ref = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedBranchesChanged: (state, action) => {
            state.step1Params.co_feed_branches_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedBranchesUnchanged: (state, action) => {
            state.step1Params.co_feed_branches_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedBondRef: (state, action) => {
            state.step1Params.co_feed_bond_ref = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetBondRef: (state, action) => {
            state.step1Params.target_bond_ref = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetBranchesChanged: (state, action) => {
            state.step1Params.target_branches_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetBranchesUnchanged: (state, action) => {
            state.step1Params.target_branches_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep2Routes: (state, action) => {
            state.step2Routes = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setExistingSettings: (state, action) => {
            state.existingSettings = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setShowSettingModal: (state, action) => {
            state.showSettingModal = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setExplicitH: (state, action) => {
            state.step1Params.explicit_H = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCheckStereo: (state, action) => {
            state.step2Params.check_stereo = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep1Result: (state, action) => {
            state.step1Result = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setSimThresh: (state, action) => {
            state.step1Params.sim_thresh = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedMol: (state, action) => {
            state.step1Params.main_feed_mol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedImg: (state, action) => {
            state.step1Params.main_feed_img = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedReference: (state, action) => {
            state.step1Params.main_feed_img_reference = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedChanged: (state, action) => {
            state.step1Params.main_feed_img_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setMainFeedUnchanged: (state, action) => {
            state.step1Params.main_feed_img_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedMol: (state, action) => {
            state.step1Params.co_feed_mol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedImg: (state, action) => {
            state.step1Params.co_feed_img = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedReference: (state, action) => {
            state.step1Params.co_feed_img_reference = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedChanged: (state, action) => {
            state.step1Params.co_feed_img_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setCoFeedUnchanged: (state, action) => {
            state.step1Params.co_feed_img_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetMol: (state, action) => {
            state.step1Params.target_mol = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetReference: (state, action) => {
            state.step1Params.target_img_reference = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetChanged: (state, action) => {
            state.step1Params.target_img_changed = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setTargetUnchanged: (state, action) => {
            state.step1Params.target_img_unchanged = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setAtomInRing: (state, action) => {
            state.step1Params.atom_in_ring = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        setStep: (state, action) => {
            state.currentStep = action.payload;

            // update localStorage
            updateLocalStorage(state);
        },
        resetState: () => {
            // update localStorage
            updateLocalStorage(initialState);

            return initialState;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setState, setLoading, setStep, setStep1Result, setMainFeedChanged, setMainFeedUnchanged, setMainFeedReference,
    setMainFeedMol, setTargetMol, setTargetChanged, setTargetUnchanged, setSimThresh, setStep2Result, setCheckStereo,
    setExplicitH, setTargetReference, setExistingSettings, setShowSettingModal, setStep2Routes, setReportData,
    resetState, setSearchStatus, setAtomInRing, setShowMoleModal, setTopNMole, setMoleType, setShowSummaryModal,
    setMainFeedBondRef, setTargetBondRef, setSearchId, setReactionNumber, setShowMoleResult,
    setMainFeedBranchesChanged, setMainFeedBranchesUnchanged, setTargetBranchesChanged, setTargetBranchesUnchanged,
    setCoFeedChanged, setCoFeedUnchanged, setCoFeedReference, setCoFeedMol, setCoFeedBondRef, setCoFeedBranchesChanged, setCoFeedBranchesUnchanged
} = reactionSlice.actions;

export default reactionSlice.reducer;