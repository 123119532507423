import awsConfig from './aws-exports';

export const InstantBaseUrl = process.env.REACT_APP_INSTANT_API_URL;
export const SearchBaseUrl = awsConfig.aws_cloud_logic_custom[0].endpoint + '/';

// search api
export const Search_API = "search";

// synthetic
export const Molfile_To_ID_API = "api/molfile_to_ID";
export const Atom_To_Smarts = "api/atomIDs_to_fragment_picture";
export const Show_Routes = "api/show_routes";
export const See_Results_Synthetic = "api/see_results_synthetic";
export const Show_Network = "api/show_network";
export const Show_Compounds = "api/show_compounds";
export const Show_Mole_By_Index = "api/show_network/view_comps_network_by_idxs";
export const Show_Mole_By_Id = "api/show_network/view_comps_network_by_IDs";
export const Show_Similar_Mols = "api/show_similar_mols";

// analogue
export const See_Results_Analogue = "api/see_results_analogue";
export const Analogue_Show_Routes = "api/show_anal_routes";
export const Analogue_Initialise = "api/v2/api_analogue_router/initialise";
export const Analogue_Search = "api/v2/api_analogue_router/search";
export const View_Ana_Para = "api/v2/api_analogue_router/view_para_analogue_routes";
export const Search_Ana_Done = "api/v2/api_analogue_router/search_done";
export const Analogue_Get_Labeled_Molecule = "api/v2/api_analogue_router/molfile_to_labeled_molecule";
export const Analogue_View_Mole_By_Id = "api/analogue_routes/view_comps_by_IDs";

// chiral
export const See_Results_Chiral = "api/see_results_chiral";
export const Chiral_Show_Routes = "api/show_chiral_reactions";
export const Chiral_View_Mole_By_Id = "api/chiral_reactions/view_comps_by_IDs";

// reaction
export const See_Results_Reaction = "api/see_results_planner";
export const Reaction_Plot_Conditions = "api/planner/plot_conditions";
export const Reaction_View_Summary = "api/planner/reactions";
export const Reaction_View_Mols = "api/planner/type_of_mols";