import React, { useEffect } from 'react';
import { Container, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './reaction.css';
import MainFeed from './mainfeed';
import CoFeed from './cofeed';
import Target from './target';
import Advanced from './advanced';
import Result from './result';
import {
    setState, resetState, setLoading, setStep, setStep1Result, setStep2Routes, setReactionNumber, setSearchStatus, setSearchId
} from './reducer';
import { postData, postSearchData, getSearchData } from '../apiHelper';
import { Search_API, Reaction_Plot_Conditions, See_Results_Reaction } from '../constant';


export default function Reaction() {

    const dispatch = useDispatch();
    const currentStep = useSelector((state) => state.reaction.currentStep);
    const isLoading = useSelector((state) => state.reaction.isLoading);
    const step1Params = useSelector((state) => state.reaction.step1Params);
    const step1Result = useSelector((state) => state.reaction.step1Result);
    const searchStatus = useSelector((state) => state.reaction.searchStatus);

    useEffect(() => {
        let useridString = localStorage.getItem("userid");

        if (useridString) {
            let params = localStorage.getItem(useridString + "-reaction-params");
            if (params) {
                let state = JSON.parse(params);
                dispatch(setState(state));

                if (state.isLoading && !state.searchId) {
                    startSearch();
                } else if (state.currentStep === 1 && state.searchId) {
                    updateSearch(state.searchId);
                }

                if (state.currentStep === 2) {
                    seeResults(); // load the image  since the result is too big to store in localStorage
                }
            }
        }
    }, []);

    const reset = () => {
        dispatch(resetState());
    }

    const updateSearch = (searchId) => {
        const checkSearchStatus = setInterval(() => {
            getSearchData(Search_API + "/" + searchId).then(
                (searchData) => {
                    console.log(searchData);
                    if (searchData) {
                        switch (searchData.status) {
                            case "PENDING":
                                dispatch(setSearchStatus("Search is pending"));
                                break;
                            case "IN_PROGRESS":
                                if (searchData.progress && searchData.progress.message) {
                                    let message = searchData.progress.message;
                                    dispatch(setSearchStatus(message));
                                    if (message.includes('No') || message.includes('stopped') || message.includes('exited')) {
                                        dispatch(setLoading(false));
                                        clearInterval(checkSearchStatus);
                                    }
                                } else {
                                    dispatch(setSearchStatus("Search is in progress"));
                                }
                                break;
                            case "COMPLETE":
                                if (searchData.progress && searchData.progress.message) {
                                    dispatch(setSearchStatus(searchData.progress.message));
                                } else {
                                    dispatch(setSearchStatus("Search completed, please click below button to see the results"));
                                }
                                dispatch(setLoading(false));
                                dispatch(setStep1Result(searchData.result));
                                clearInterval(checkSearchStatus);
                                break;
                            case "ERROR":
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                            default:
                                dispatch(setSearchStatus("Searching is stopped and no useful information can be found. Please try other molecules or different set of parameters."));
                                dispatch(setLoading(false));
                                clearInterval(checkSearchStatus);
                                break;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                    clearInterval(checkSearchStatus);
                }
            );
        }, 5000);
    }

    const startSearch = () => {
        if (!step1Params.main_feed_mol) {
            alert("Please specify main feedstock!");
            return;
        }
        if (!step1Params.co_feed_mol) {
            alert("Please specify co feedstock!");
            return;
        }
        if (!step1Params.target_mol) {
            alert("Please specify a target!");
            return;
        }

        dispatch(setLoading(true));
        dispatch(setSearchStatus("Not Started"));
        dispatch(setSearchId(""));

        let request = {
            search_type: "exper",
            search_params: {
                initialise: {
                    feeds: [{
                        molblock: step1Params.main_feed_mol,
                        bond_ref: step1Params.main_feed_bond_ref,
                        branches_unchange: step1Params.main_feed_branches_unchanged,
                        branches_change: step1Params.main_feed_branches_changed
                    }, {
                        molblock: step1Params.co_feed_mol,
                        bond_ref: step1Params.co_feed_bond_ref,
                        branches_unchange: step1Params.co_feed_branches_unchanged,
                        branches_change: step1Params.co_feed_branches_changed
                    }],
                    target: {
                        molblock: step1Params.target_mol,
                        bond_ref: step1Params.target_bond_ref,
                        branches_unchange: step1Params.target_branches_unchanged,
                        branches_change: step1Params.target_branches_changed
                    },
                    similarity: {
                        sim_thresh: parseFloat(step1Params.sim_thresh),
                        fp_type: 1
                    },
                    explicit_H: step1Params.explicit_H === "True" ? true : false,
                    ring: step1Params.atom_in_ring === "True" ? true : false,
                    analogue_comp_size_coeff: 2,
                    addHs: false,
                    sort_atm_idxs: true,
                    molblock_type: "marvin",
                    comps_pool_size: null
                },
                route_search: {
                    rxn_steps: 1,
                    check_stereo: false,
                    walk_steps: 3,
                    max_search_time: 60
                },
                compile_results: {
                    top_n_rxns: 1000
                }
            }
        };

        postSearchData(Search_API, request).then(
            (result) => {
                if (result && result.message === "Created") {
                    dispatch(setStep1Result(null));

                    if (result.searchId) {
                        dispatch(setSearchId(result.searchId));
                        updateSearch(result.searchId);
                    }
                } else {
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    const seeResults = () => {
        dispatch(setLoading(true));

        postData(See_Results_Reaction).then(
            (result) => {
                if (result && result.top_n_rxns) {
                    dispatch(setReactionNumber(result.top_n_rxns));

                    postData(Reaction_Plot_Conditions).then(
                        (routesData) => {
                            if (routesData) {
                                console.log(routesData);
                                dispatch(setStep2Routes(routesData));
                                dispatch(setStep(2));
                                dispatch(setLoading(false));
                            } else {
                                alert("Server error, please try again!");
                                dispatch(setLoading(false));
                            }
                        },
                        (error) => {
                            console.log(error);
                            dispatch(setLoading(false));
                        }
                    );
                } else {
                    alert("Server error, please try again!");
                    dispatch(setLoading(false));
                }
            },
            (error) => {
                console.log(error);
                dispatch(setLoading(false));
            }
        );
    }

    return (
        <div className='reaction'>
            <Container>
                <div className='title'>
                    Search Reaction Conditions

                    {currentStep === 1 &&
                        <div className='reset'>
                            <Button variant="warning" onClick={reset}>
                                Reset
                            </Button>
                        </div>
                    }
                </div>
                <div className='sub-title'>
                    {currentStep === 1 &&
                        <strong>Stage 1: Set up parameters and initiate search</strong>
                    }
                    {currentStep === 2 &&
                        <strong>Stage 2: View reaction conditions</strong>
                    }
                </div>
                {currentStep === 1 &&
                    <>
                        <div className='description'>
                            This application is designed for high-throughput screenings. It returns reaction conditions of a reaction defined in this page. The results summarize temperatures, pressures, reaction time, pH, solvents, reagents and catalysts of the same reaction type.
                        </div>
                        <MainFeed />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <CoFeed />
                        <hr style={{ marginTop: 30 + 'px' }} />
                        <Target />

                        <hr style={{ marginTop: 30 + 'px' }} />
                        <div>
                            <Button onClick={startSearch} variant="primary" disabled={isLoading}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                Start Route Search
                            </Button>
                        </div>
                        {searchStatus !== "Not Started" &&
                            <div className="description" style={{ marginTop: 20 + 'px' }}>
                                {searchStatus}
                            </div>
                        }
                        <div className='see-results'>
                            <Button variant="warning" onClick={seeResults} disabled={isLoading || step1Result == null}>
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: 5 + 'px' }}
                                    />
                                }
                                See Results
                            </Button>
                        </div>
                        <Advanced />
                    </>
                }
                {currentStep === 2 &&
                    <Result />
                }
            </Container>
        </div>
    );
}